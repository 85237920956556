import React from 'react'
import { Stack, Main,} from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'


export default () => (
  <>
    <Seo title='FAQs' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="FAQs"
          subheader='This is a small guideline for sharing and partecipating to our community. We can not wait to hear your voice! First of all, we would like to say "thank you!", it means so much when people decide to share our contents ; ) '
        />
        <h1>
          How can I partecipate to Ftt community?
        </h1>
        <p>
          Please, join our discussion boards on Facebook, Reddit or Quora. Get updates and partecipate into comments on Instagram, Tik Tok or Anchor.
          You will find all our links here in the page! 
        </p>
        <h1>
          May I suggest you a new topic, or project to look at?
        </h1>
        <p>
          I know, this is not a Faq...but we look forward to hearing from you any suggestion about new techs, projects or upcoming brands.
          Fell free to link relate into post comment if it looks appropriate or DM if you want to share some docs.
        </p>
        <h1>
          Can I share any of your articles on my blog?
        </h1>
        <p>
          Yes, you are welcome...and be sure to 'Share as It Is' and link to the URL of the original article! Here how to do it correctly. Please, do not modify any content part. Take care to integrate external links as from original article. Also take care to integrate media if they are copyrighted to FashionTech Talks.
        </p>
        <h1>
          Can I repost or share your contents over social media?
        </h1>
        <p>
         You will find our content all over social media, as we share it on Facebook, Instagram, Tik Tok and via Podcast. 
         For summaries, social sharing and images, we ask that you adhere to the following guidelines.
        <ul><li>
        Tag 'FashionTech Talk' in your repost.
        </li><li>
        Cite 'Fttalks.com' as the original source.
        </li><li>
        Link to the source on Ftt website:

        Article/blog post summaries:  Link to the URL of the original article.
        Images: Link to the URL where our original image is stored.
        Embedded infographics, video and SlideShares: Embed the original infographic, video, or presentation using the provided embed code.</li>
        </ul>
        </p>
        <h1>
          Can I print publish your contents?
        </h1>
        <p>
          Sorry, we do not allow print publication of original FashionTech Talks contents.
        </p>
        <h1>
          Disclaimer
        </h1>
        <p>
         By republishing articles online you agree to follow and respect these guidelines and you agree to immediately remove our content from your website if we request to do so.
         The use of said contents, without prior approval, that violates this policy may require further administrative or legal action.
        </p>
        <p>Latest update: January 18, 2021</p>


      </Main>
    </Stack>
  </>
)
